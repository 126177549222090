<script>
export default {
   props: {
      pageChanged: {
         required: true,
         type: Function,
      },
      perPageChanged: {
         required: true,
         type: Function,
      },
      total: {
         required: true,
         type: Number,
      },
      pageLength: {
         required: true,
         type: Number,
      },
      pageOptions: {
         required: true,
         type: Array,
      },
   },
   data() {
      return {
         page: 1,
         pageLengthLocal: 0,
      }
   },
   mounted() {
      this.pageLengthLocal = this.pageLength
   },
   methods: {
      onPerPageChange(perPage) {
         this.page = 1
         this.pageChanged({ currentPage: this.page })
         this.perPageChanged({ currentPerPage: perPage })
         this.$emit('update:perpage', perPage)
      },
      onPageChange(page) {
         this.pageChanged({ currentPage: page })
         this.$emit('update:page', page)
      },
   },
}
</script>

<template>
   <div class="d-flex justify-content-between flex-wrap">
      <!-- page length -->
      <div class="d-flex align-items-center mb-0 mt-1">
         <!-- <span class="text-nowrap"> Page {{ pageLength }} to </span> -->
         <span class="text-nowrap">Total baris : {{ total }}</span>
         <b-form-select
            v-model="pageLengthLocal"
            :options="pageOptions"
            class="mx-1"
            size="sm"
            @input="onPerPageChange"
         />
      </div>

      <div>
         <b-pagination
            :value="page"
            :total-rows="total"
            :per-page="pageLengthLocal"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="onPageChange"
         >
            <template #prev-text>
               <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
               <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
         </b-pagination>
      </div>
   </div>
</template>
